import axios from '../lib/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 1- Create Dossier
export const createDossier = createAsyncThunk(
  'signature/createDossier',
  async ({ service_request_id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('/user/top/createDossier', {
        service_request_id,
      })
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 2- Start Process
export const startProcess = createAsyncThunk(
  'signature/startProcess',
  async ({ service_request_id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('/user/top/process-start', {
        service_request_id,
      })
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 3- Oneshot Register
export const oneshotRegister = createAsyncThunk(
  'signature/oneshotRegister',
  async (formData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post(
        '/user/top/oneshot-register',
        formData,
        { headers: { 'Content-Type': 'application/json' } }
      )
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 4- Oneshot Send OTP
export const oneshotSendOTP = createAsyncThunk(
  'signature/oneshotSendOTP',
  async ({ service_request_id }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('/user/top/oneshot-sendOTP', {
        service_request_id,
      })
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 5- Oneshot Issue and Sign
export const oneshotIssueAndSign = createAsyncThunk(
  'signature/oneshotIssueAndSign',
  async (formData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post(
        '/user/top/oneshot-issueAndSign',
        formData,
        {
          headers: { 'Content-Type': 'application/json' },
        }
      )
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const signatureSlice = createSlice({
  name: 'signature',
  initialState: {
    loading: false,
    otpLoading: false,
    signature_document_url: null,
    signature_profile_document_url: null,
    clauses: null,
    signaturesArr: null,
    signed_documents: null,
  },
  extraReducers: (builder) => {
    // 1- Create Dossier
    builder.addCase(createDossier.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createDossier.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(createDossier.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })

    // 2- Start Process
    builder.addCase(startProcess.pending, (state) => {
      state.loading = true
      state.error = null
      state.clauses = null
      state.signature_document_url = null
      state.signature_profile_document_url = null
    })
    builder.addCase(startProcess.fulfilled, (state, action) => {
      state.loading = false
      state.clauses = action.payload.data.data.clauses
      state.signature_document_url = action.payload.signature_document_url
      state.signature_profile_document_url =
        action.payload.signature_profile_document_url
    })
    builder.addCase(startProcess.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
      state.clauses = null
      state.signature_document_url = null
      state.signature_profile_document_url = null
    })

    // 3- Oneshot Register
    builder.addCase(oneshotRegister.pending, (state) => {
      state.otpLoading = true
      state.error = null
    })
    builder.addCase(oneshotRegister.fulfilled, (state, action) => {
      const signaturesArr = action.payload.data.data.documents.map((doc) => ({
        id: doc.id,
        clauses: doc.signatures.map((signature) => ({
          id: signature.id,
          value: true,
        })),
      }))
      state.otpLoading = false
      state.signaturesArr = signaturesArr
    })
    builder.addCase(oneshotRegister.rejected, (state, action) => {
      state.error = action.payload
      state.otpLoading = false
    })

    // 4- Oneshot Send OTP
    builder.addCase(oneshotSendOTP.pending, (state) => {
      state.otpLoading = true
      state.error = null
    })
    builder.addCase(oneshotSendOTP.fulfilled, (state, action) => {
      state.otpLoading = false
    })
    builder.addCase(oneshotSendOTP.rejected, (state, action) => {
      state.error = action.payload
      state.otpLoading = false
    })

    // 5- Oneshot Issue and Sign
    builder.addCase(oneshotIssueAndSign.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(oneshotIssueAndSign.fulfilled, (state, action) => {
      state.loading = false

      const returnedData = action.payload
      let signedDocuments = {}

      if (
        returnedData.profile_document_name &&
        returnedData.document_profile_url
      ) {
        signedDocuments.profile_signature = {
          file_name: returnedData.profile_document_name,
          file_url: returnedData.document_profile_url,
        }
      }

      signedDocuments.request_signature = {
        file_name: returnedData.service_document_name,
        file_url: returnedData.document_url,
      }

      state.signed_documents = signedDocuments
    })
    builder.addCase(oneshotIssueAndSign.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })
  },
})

export default signatureSlice.reducer
