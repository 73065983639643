import axios from '../lib/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 1- GET ALL PAYMENTS
export const getAllPayments = createAsyncThunk(
  'payment/getAllPayments',
  async (filterData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { page, search, from_date, to_date, service_slug } = filterData

    try {
      const { data } = await axios.get(
        `/user/payments?page=${page}${
          search !== undefined ? `&search=${search}` : ''
        }${from_date ? `&from_date=${from_date}` : ''}${
          to_date ? `&to_date=${to_date}` : ''
        }${service_slug ? `&service_slug=${service_slug}` : ''}`
      )

      return {
        payments: data.payments.data,
        pagination: {
          current: data.payments.meta.current_page,
          from: data.payments.meta.from,
          to: data.payments.meta.to,
          total: data.payments.meta.total,
          last: data.payments.meta.last_page,
        },
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 2- CHECKOUT
export const checkout = createAsyncThunk(
  'payment/checkout',
  async (service_request_id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('payment/checkout/session', {
        service_request_id,
      })

      return data.checkout_session_url
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 3- CREATE REFUND REQUEST
export const createRefundRequest = createAsyncThunk(
  'payment/createRefundRequest',
  async (formValues, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI

    try {
      const { data } = await axios.post('v1/refund/requests', formValues)
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    payments: [],
    pagination: null,
    checkout_session_url: null,
    loading: false,
    checkoutLoading: false,
    createRefundLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    // 1- GET ALL PAYMENTS
    builder.addCase(getAllPayments.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getAllPayments.fulfilled, (state, action) => {
      state.payments = action.payload.payments
      state.pagination = action.payload.pagination
      state.loading = false
    })
    builder.addCase(getAllPayments.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })

    // 2- CHECKOUT
    builder.addCase(checkout.pending, (state) => {
      state.checkoutLoading = true
      state.error = null
    })
    builder.addCase(checkout.fulfilled, (state, action) => {
      state.checkout_session_url = action.payload.checkout_session_url
      state.checkoutLoading = false
    })
    builder.addCase(checkout.rejected, (state, action) => {
      state.error = action.payload
      state.checkoutLoading = false
    })

    // 3- CREATE REFUND REQUEST
    builder.addCase(createRefundRequest.pending, (state) => {
      state.createRefundLoading = true
    })
    builder.addCase(createRefundRequest.fulfilled, (state, action) => {
      state.createRefundLoading = false
    })
    builder.addCase(createRefundRequest.rejected, (state, action) => {
      state.createRefundLoading = false
    })
  },
})

export default paymentSlice.reducer
