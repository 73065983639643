import axios from '../lib/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 1- APPLY PROMO CODE
export const applyPromoCode = createAsyncThunk(
  'promoCode/applyPromoCode',
  async ({ intent_id, code }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('apply/promotion_code', {
        intent_id,
        code,
      })

      return data.result
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 2- MIS-APPLY PROMO CODE
export const misApplyPromoCode = createAsyncThunk(
  'promoCode/misApplyPromoCode',
  async ({ intent_id, code }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('misapply/promotion_code', {
        intent_id,
        code,
      })

      return data.result
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const promoCodeSlice = createSlice({
  name: 'promoCode',
  initialState: {
    loading: false,
    loadingDisable: false
  },
  extraReducers: (builder) => {
     builder.addCase(applyPromoCode.pending, (state) => {
       state.loading = true
     })
     builder.addCase(applyPromoCode.fulfilled, (state) => {
       state.loading = false
     })
     builder.addCase(applyPromoCode.rejected, (state) => {
       state.loading = false
     })

     builder.addCase(misApplyPromoCode.pending, (state) => {
       state.loadingDisable = true
     })
     builder.addCase(misApplyPromoCode.fulfilled, (state) => {
       state.loadingDisable = false
     })
     builder.addCase(misApplyPromoCode.rejected, (state) => {
       state.loadingDisable = false
     })
  },
})

export default promoCodeSlice.reducer