import { useEffect } from 'react'
import Script from 'next/script'
import { ConfigProvider } from 'antd'
import TagManager from 'react-gtm-module'
import { appWithTranslation } from 'next-i18next'
import { Provider } from 'react-redux'
import { wrapper } from '../redux/store'
import itIT from 'antd/locale/it_IT'

import 'dayjs/locale/it'

// CSS FILES
import '../styles/globals.css'
import '../styles/formInputs.css'
import '../styles/layout.css'
import '../styles/tableFilters.css'
import '../styles/table.css'
import '../styles/steps.css'

export function App({ Component, ...rest }) {
  const { store, props } = wrapper.useWrappedStore(rest)

  const Layout = Component.Layout || (({ children }) => <>{children}</>)

  useEffect(() => {
    // Google Tag Manager for production only
    if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_ENVIRONMET === 'production') {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
        dataLayerName: 'PageDataLayer',
      })
    }

    // Iubenda script
    // if (typeof window !== 'undefined' && document.body) {
    //   const iubendaScript = document.createElement('script')
    //   iubendaScript.type = 'text/javascript'
    //   iubendaScript.innerHTML = `
    //     var _iub = _iub || {};
    //     _iub.cons_instructions = _iub.cons_instructions || [];
    //     _iub.cons_instructions.push(["init", {api_key: "LhY5qN7PWAuBSylX7cO2iE0xqbr8tvFM"}]);
    //   `
    //   document.body.appendChild(iubendaScript)
    // }

    // Hotjar Script
    if (typeof window !== 'undefined' && document.body && process.env.NEXT_PUBLIC_ENVIRONMET === 'production') {
      (function(h,o,t,j,a,r){
        h.hj = h.hj || function() { (h.hj.q = h.hj.q || []).push(arguments) };
        h._hjSettings = { hjid: 3629008, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = true;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    }
  }, [])

  return (
    <>
      {/* <Script
        id='ubendacsConfiguration'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
            var _iub = _iub || [];
            _iub.csConfiguration = {
              "askConsentAtCookiePolicyUpdate":true,
              "floatingPreferencesButtonDisplay":"bottom-left",
              "perPurposeConsent":true,
              "siteId":3277826,
              "cookiePolicyId":15443742,
              "lang":"it",
              "banner": {
                "acceptButtonColor":"#8FC045",
                "acceptButtonDisplay":true,
                "backgroundColor":"#262F39",
                "closeButtonRejects":true,
                "customizeButtonCaptionColor":"#8FC045",
                "customizeButtonColor":"#1B222A",
                "customizeButtonDisplay":true,
                "explicitWithdrawal":true,
                "listPurposes":true,
                "logo":null,
                "linksColor":"#FFFFFF",
                "position":"bottom",
                "rejectButtonColor":"#8FC045",
                "rejectButtonDisplay":true
              }
            };
          `,
        }}
      /> */}
      {/* <Script
        src='https://cs.iubenda.com/autoblocking/3277826.js'
        strategy='afterInteractive'
      />
      <Script
        src='//cdn.iubenda.com/cs/gpp/stub.js'
        strategy='afterInteractive'
      />
      <Script
        src='//cdn.iubenda.com/cs/iubenda_cs.js'
        strategy='afterInteractive'
      />
      <Script src='https://cdn.iubenda.com/cons/iubenda_cons.js' async /> */}
      <ConfigProvider
        locale={itIT}
        theme={{
          token: {
            colorPrimary: '#8FC045',
          },
        }}
      >
        <Provider store={store}>
          <Layout>
            <Component {...props.pageProps} />
          </Layout>
        </Provider>
      </ConfigProvider>
    </>
  )
}

export default appWithTranslation(App)
