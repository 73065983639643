import axios from '../lib/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 1- GET ALL REQUESTS
export const getAllRequests = createAsyncThunk(
  'request/getAllRequests',
  async (filterData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { page, search, from_date, to_date, service_id, status } = filterData
    try {
      const { data } = await axios.get(
        `/user/requests?${page !== undefined ? `&page=${page}` : 'page=1'}${
          search !== undefined ? `&search=${search}` : ''
        }${from_date ? `&from_date=${from_date}` : ''}${
          to_date ? `&to_date=${to_date}` : ''
        }${service_id ? `&service_id=${service_id}` : ''}${
          status ? `&status=${status}` : ''
        }`
      )
      return {
        requests: data.requests.data,
        pagination: {
          current: data.requests.meta.current_page,
          from: data.requests.meta.from,
          to: data.requests.meta.to,
          total: data.requests.meta.total,
          last: data.requests.meta.last_page,
        },
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 2- GET REQUEST DETAILS
export const requestDetails = createAsyncThunk(
  'request/requestDetails',
  async ({ id, locale }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const config = {
      headers: {
        lang: locale,
      },
    }

    try {
      const { data } = await axios.get(`/user/request/${id}`, config)
      return data.request
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 3- UPLOAD MISSING INFO
export const uploadMissingInfo = createAsyncThunk(
  'request/uploadMissingInfo',
  async (formData, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI
    try {
      const { data } = await axios.post(
        '/user/request/upload/missing_info',
        formData
      )
      dispatch(getAllRequests({}))
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const requestSlice = createSlice({
  name: 'request',
  initialState: {
    requests: [],
    request: {},
    pagination: null,
    loading: false,
    loadingDetails: false,
    error: null,
  },
  extraReducers: (builder) => {
    // 1- GET ALL REQUESTS
    builder.addCase(getAllRequests.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getAllRequests.fulfilled, (state, action) => {
      state.requests = action.payload.requests
      state.pagination = action.payload.pagination
      state.loading = false
    })
    builder.addCase(getAllRequests.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })

    // 2- GET REQUEST DETAILS
    builder.addCase(requestDetails.pending, (state) => {
      state.loadingDetails = true
      state.error = null
    })
    builder.addCase(requestDetails.fulfilled, (state, action) => {
      state.request = action.payload
      state.loadingDetails = false
    })
    builder.addCase(requestDetails.rejected, (state, action) => {
      state.error = action.payload
      state.loadingDetails = false
    })

    // 3- UPLOAD MISSING INFO
    builder.addCase(uploadMissingInfo.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(uploadMissingInfo.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(uploadMissingInfo.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })
  },
})

export default requestSlice.reducer
