import axios from 'axios'
import Cookies from 'js-cookie'

const token = Cookies.get('SMARTCAF_USER_JWT_TOKEN')
const lang = Cookies.get('i18next_SMARTCAF_LANG')

const api = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_HOST}`,
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept: 'application/json',
    lang,
  },
})

api.interceptors.request.use((config) => {
  if (token) {
    config.headers.Authorization = `${token}`
  }

  return config
})

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      const redirectionUrl = window.location.pathname
      Router.push(`/?redirection_link=${redirectionUrl}`)
    }
    return Promise.reject(error)
  }
)

// Custom base query that wraps the Axios instance
export const axiosBaseQuery =
  () =>
  async ({ url, method, data, params, headers }) => {
    try {
      const result = await api({
        url,
        method,
        data,
        params,
        headers,
      })
      return { data: result.data }
    } catch (axiosError) {
      return { error: axiosError }
    }
  }

export default api
