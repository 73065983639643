import axios from '../lib/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 1- UPLOAD FILE
export const uploadFile = createAsyncThunk(
  'tempUpload/uploadFile',
  async ({ formData, onUploadProgress }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    try {
      const { data } = await axios.post('/upload_file', formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          onUploadProgress(percentCompleted)
        },
      })
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 2- REMOVE FILE
export const removeFile = createAsyncThunk(
  'tempUpload/removeFile',
  async (path, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    try {
      const { data } = await axios.post('/remove_file', { path })
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const tempUploadSlice = createSlice({
  name: 'tempUpload',
  initialState: {
    loadingUpload: false,
    loadingDelete: false,
  },
  extraReducers: (builder) => {
    // 1- UPLOAD FILE
     builder.addCase(uploadFile.pending, (state) => {
       state.loadingUpload = true
     })
     builder.addCase(uploadFile.fulfilled, (state) => {
       state.loadingUpload = false
     })
     builder.addCase(uploadFile.rejected, (state) => {
       state.loadingUpload = false
     })

    // 2- REMOVE FILE
    builder.addCase(removeFile.pending, (state) => {
      state.loadingDelete = true
    })
    builder.addCase(removeFile.fulfilled, (state) => {
      state.loadingDelete = false
    })
    builder.addCase(removeFile.rejected, (state) => {
      state.loadingDelete = false
    })
  }
})

export default tempUploadSlice.reducer