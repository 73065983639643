import axios from '../lib/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 1- QUERY CONTACTS
export const queryContacts = createAsyncThunk(
  'contact/queryContacts',
  async (filterData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    const { page, status, token } = filterData
    try {
      const { data } = await axios.get(
        `/support_tickets?page=${page}${status ? `&status=${status}` : ''}${
          token ? `&conversation_token=${token}` : ''
        }`
      )
      return {
        items: data.items.data,
        pagination: {
          current: data.items.meta.current_page,
          from: data.items.meta.from,
          to: data.items.meta.to,
          total: data.items.meta.total,
          last: data.items.meta.last_page,
        },
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 2- CONTACT DETAILS
export const contactDetails = createAsyncThunk(
  'contact/contactDetails',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.get(`/support_tickets/${id}/messages`)
      return data.messages
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 3- CREATE CONTACT
export const createContact = createAsyncThunk(
  'contact/createContact',
  async (formData, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI

    try {
      const { data } = await axios.post('/support_ticket', formData)
      dispatch(queryContacts({}))
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 4- REPLY CONTACT
export const replyContact = createAsyncThunk(
  'contact/replyContact',
  async (formData, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI

    try {
      const { data } = await axios.post('/support_tickets/reply', formData)
      dispatch(queryContacts({}))
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 5- CLOSE CONTACT
export const closeContact = createAsyncThunk(
  'contact/closeContact',
  async (id, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI

    try {
      const { data } = await axios.get(`/support_tickets/${id}/close`)
      dispatch(queryContacts({}))
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 6- DELETE CONTACT
export const deleteContact = createAsyncThunk(
  'contact/deleteContact',
  async (id, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI

    try {
      const { data } = await axios.delete(`/admin/contact_us/${id}/delete`)
      dispatch(queryContacts({}))
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const contactSlice = createSlice({
  name: 'contact',
  initialState: {
    items: [],
    messages: null,
    pagination: null,
    allLoading: false,
    loading: false,
    error: null,
    errors: null,
  },
  reducers: {
    clearError: (state) => {
      state.errors = null
      state.error = null
    },
  },
  extraReducers: (builder) => {
    // 1- QUERY CONTACTS
    builder.addCase(queryContacts.pending, (state) => {
      state.allLoading = true
      state.error = null
    })
    builder.addCase(queryContacts.fulfilled, (state, action) => {
      state.items = action.payload.items
      state.pagination = action.payload.pagination
      state.allLoading = false
    })
    builder.addCase(queryContacts.rejected, (state, action) => {
      state.error = action.payload
      state.allLoading = false
    })

    // 2- CONTACT DETAILS
    builder.addCase(contactDetails.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(contactDetails.fulfilled, (state, action) => {
      state.messages = action.payload
      state.loading = false
    })
    builder.addCase(contactDetails.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })

    // 3- CREATE CONTACT
    builder.addCase(createContact.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(createContact.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(createContact.rejected, (state, action) => {
      state.loading = false
      state.errors = action.payload.response.data.errors
      state.error = action.payload.response.data.message
    })

    // 4- REPLY CONTACT
    builder.addCase(replyContact.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(replyContact.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(replyContact.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload.response.data.message
    })

    // 5- CLOSE CONTACT
    builder.addCase(closeContact.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(closeContact.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(closeContact.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload.response.data.message
    })

    // 6- DELETE CONTACT
    builder.addCase(deleteContact.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(deleteContact.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(deleteContact.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload.response.data.message
    })
  },
})

export const { clearError } = contactSlice.actions

export default contactSlice.reducer
