import axios from '../lib/axios'
import TagManager from 'react-gtm-module'
import Cookies from 'js-cookie'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 1- REGISTER
export const register = createAsyncThunk(
  'auth/register',
  async (formData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('/user/register', formData)

      // GTM
      const tagManagerArgs = {
        dataLayer: {
          event: 'register',
          eventCategory: 'Registration',
          eventAction: null,
          eventLabel: 'user',
        },
        dataLayerName: 'PageDataLayer',
      }
      TagManager.dataLayer(tagManagerArgs)

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 2- LOGIN
export const login = createAsyncThunk(
  'auth/login',
  async (formData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('/user/login', formData)

      // GTM
      const tagManagerArgs = {
        dataLayer: {
          event: 'login',
          eventCategory: 'Login',
          eventAction: 'user',
        },
        dataLayerName: 'PageDataLayer',
      }
      TagManager.dataLayer(tagManagerArgs)

      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 3- GET PROFILE DATA
export const profileData = createAsyncThunk(
  'auth/profileData',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.get('/user/profile')
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 4- SEND OTP
export const sendOTP = createAsyncThunk(
  'auth/sendOTP',
  async (email, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('/reset_password/send/opt', {
        email,
      })
      return {
        data,
        email,
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 5- VERIFY OTP
export const verifyOTP = createAsyncThunk(
  'auth/verifyOTP',
  async (formData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('/reset_password/verify/opt', formData)
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 6- RESET PASSWORD
export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (formData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('/reset_password', formData)
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 7- UPDATE PROFILE
export const updateProfile = createAsyncThunk(
  'auth/updateProfile',
  async (formData, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('/user/profile', formData)
      dispatch(getProfileStatus())
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 8- UPDATE PASSWORD
export const updatePassword = createAsyncThunk(
  'auth/updatePassword',
  async (formData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('/password/edit', formData)
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 9- UPDATE NUMBER
export const updateNumber = createAsyncThunk(
  'auth/updateNumber',
  async (formData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('/mobile_number/update', formData)
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 10- RESEND OTP FOR MOBILE NUMBER
export const resendOTP = createAsyncThunk(
  'auth/resendOTP',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.get('/mobile_number/resend/otp')
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 11- VERIFY MOBILE OTP
export const verifyOTPMobile = createAsyncThunk(
  'auth/verifyOTPMobile',
  async (formData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('/mobile_number/verify', formData)
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 12- SOCIAL LOGIN
export const socialLogin = createAsyncThunk(
  'auth/socialLogin',
  async (code, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('/user/auth/social/login', { code })
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 13- DELETE PROFILE FILE
// FILE TYPE [national_id_file - residence_file - tax_health_card_file]
export const deleteProfileFile = createAsyncThunk(
  'auth/deleteProfileFile',
  async (data, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI
    const { fileType, fileID } = data

    try {
      const { data } = await axios.delete(`/${fileType}/${fileID}`)
      dispatch(getProfileStatus())
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 14- UPDATE EMAIL ADDRESS
export const updateEmail = createAsyncThunk(
  'auth/updateEmail',
  async (email, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('/email_address/update', {
        _method: 'PUT',
        email,
      })
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 15- VERIFY EMAIL ADDRESS
export const verifyEmail = createAsyncThunk(
  'auth/verifyEmail',
  async (formData, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI
    try {
      const { data } = await axios.post('/email_address/verify', formData)
      dispatch(profileData())
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 16- GET PROFILE STATUS
export const getProfileStatus = createAsyncThunk(
  'auth/getProfileStatus',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.get('/user/profile/status')
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 17- DELETE ACCOUNT
export const deleteAccount = createAsyncThunk(
  'auth/deleteAccount',
  async (password, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('/user/account', { password })
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    isAuth: false,
    profile: null,
    errors: null,
    errorMsg: null,
    resetPasswordEmail: null,
    resetPasswordToken: null,
    updateProfileLoading: false,
    deleteLoading: false,
    updateLoading: false,
    profileStatusLoading: false,
    deleteAccountLoading: false,
    errorDelete: null,
    isCompleted: false,
    isCompletedBefore: false,
  },
  reducers: {
    clearError: (state) => {
      state.errors = null
      state.errorMsg = null
    },
    logout: (state) => {
      Cookies.remove('SMARTCAF_USER_JWT_TOKEN')
      state.profile = null
      state.isAuth = false
    },
  },
  extraReducers: (builder) => {
    // 1- REGISTER
    builder.addCase(register.pending, (state) => {
      state.loading = true
      state.errors = null
      state.errorMsg = null
    })
    builder.addCase(register.fulfilled, (state, action) => {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${action.payload.token}`,
      }
      Cookies.set('SMARTCAF_USER_JWT_TOKEN', `Bearer ${action.payload.token}`, {
        expires: 8765,
        secure: true,
        sameSite: 'strict',
      })
      Cookies.set('SMARTCAF_USER_REGISTERED', true)
      state.profile = action.payload.profile
      state.isAuth = true
      state.loading = false
    })
    builder.addCase(register.rejected, (state, action) => {
      state.errors = action.payload.response.data.errors
      state.errorMsg = action.payload.response.data.message
      state.loading = false
    })

    // 2- LOGIN
    builder.addCase(login.pending, (state) => {
      state.loading = true
      state.errors = null
      state.errorMsg = null
    })
    builder.addCase(login.fulfilled, (state, action) => {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${action.payload.token}`,
      }
      Cookies.set('SMARTCAF_USER_JWT_TOKEN', `Bearer ${action.payload.token}`, {
        expires: 8765,
        secure: true,
        sameSite: 'strict',
      })
      Cookies.set('SMARTCAF_USER_REGISTERED', true)
      state.profile = action.payload.profile
      state.isAuth = true
      state.loading = false
    })
    builder.addCase(login.rejected, (state, action) => {
      state.errors = action.payload.response.data.errors
      state.errorMsg = action.payload.response.data.message
      state.loading = false
    })

    // 3- GET PROFILE DATA
    builder.addCase(profileData.pending, (state) => {
      state.loading = true
      state.errorMsg = null
    })
    builder.addCase(profileData.fulfilled, (state, action) => {
      Cookies.set('SMARTCAF_USER_REGISTERED', true)
      state.loading = false
      state.isAuth = true
      state.profile = action.payload.profile
      state.isCompletedBefore =
        action.payload.profile.profile_completed_before === 1 ? true : false
    })
    builder.addCase(profileData.rejected, (state, action) => {
      state.loading = false
      state.isAuth = false
    })

    // 4- SEND OTP
    builder.addCase(sendOTP.pending, (state) => {
      state.loading = true
      state.errorMsg = null
    })
    builder.addCase(sendOTP.fulfilled, (state, action) => {
      state.loading = false
      state.successMsg = action.payload.data.message
      state.resetPasswordEmail = action.payload.email
    })
    builder.addCase(sendOTP.rejected, (state, action) => {
      state.errorMsg = action.payload.response.data.message
      state.loading = false
    })

    // 5- VERIFY OTP
    builder.addCase(verifyOTP.pending, (state) => {
      state.loading = true
      state.errorMsg = null
    })
    builder.addCase(verifyOTP.fulfilled, (state, action) => {
      state.loading = false
      state.resetPasswordToken = action.payload.reset_password_token
    })
    builder.addCase(verifyOTP.rejected, (state, action) => {
      state.errorMsg = action.payload.response.data.message
      state.loading = false
    })

    // 6- RESET PASSWORD
    builder.addCase(resetPassword.pending, (state) => {
      state.loading = true
      state.errorMsg = null
    })
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = false
      state.successMsg = action.payload.message
    })
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.errorMsg = action.payload.response.data.message
      state.errors = action.payload.response.data.errors
      state.loading = false
    })

    // 7- UPDATE PROFILE
    builder.addCase(updateProfile.pending, (state) => {
      state.updateProfileLoading = true
      state.errorMsg = null
    })
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.updateProfileLoading = false
      state.profile = action.payload.profile
      state.successMsg = action.payload.message
    })
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.errorMsg = action.payload.response.data.message
      state.errors = action.payload.response.data.errors
      state.updateProfileLoading = false
    })

    // 8- UPDATE PASSWORD
    builder.addCase(updatePassword.pending, (state) => {
      state.updateLoading = true
      state.errorMsg = null
    })
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.updateLoading = false
      state.successMsg = action.payload.message
    })
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.errorMsg = action.payload.response.data.message
      state.errors = action.payload.response.data.errors
      state.updateLoading = false
    })

    // 9- UPDATE NUMBER
    builder.addCase(updateNumber.pending, (state) => {
      state.updateLoading = true
      state.errorMsg = null
    })
    builder.addCase(updateNumber.fulfilled, (state, action) => {
      state.updateLoading = false
      state.successMsg = action.payload.message
    })
    builder.addCase(updateNumber.rejected, (state, action) => {
      state.errorMsg = action.payload.response.data.message
      state.errors = action.payload.response.data.errors
      state.updateLoading = false
    })

    // 10- RESEND OTP FOR MOBILE NUMBER
    builder.addCase(resendOTP.pending, (state) => {
      state.loading = true
      state.errorMsg = null
    })
    builder.addCase(resendOTP.fulfilled, (state, action) => {
      state.loading = false
      state.successMsg = action.payload.message
    })
    builder.addCase(resendOTP.rejected, (state, action) => {
      state.errorMsg = action.payload.response.data.message
      state.loading = false
    })

    // 11- VERIFY MOBILE OTP
    builder.addCase(verifyOTPMobile.pending, (state) => {
      state.updateLoading = true
      state.errorMsg = null
    })
    builder.addCase(verifyOTPMobile.fulfilled, (state, action) => {
      state.updateLoading = false
      state.successMsg = action.payload.message
    })
    builder.addCase(verifyOTPMobile.rejected, (state, action) => {
      state.errorMsg = action.payload.response.data.message
      state.updateLoading = false
    })

    // 12- SOCIAL LOGIN
    builder.addCase(socialLogin.pending, (state) => {
      state.loading = true
      state.errors = null
      state.errorMsg = null
    })
    builder.addCase(socialLogin.fulfilled, (state, action) => {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${action.payload.token}`,
      }
      Cookies.set('SMARTCAF_USER_JWT_TOKEN', `Bearer ${action.payload.token}`, {
        expires: 8765,
        secure: true,
        sameSite: 'strict',
      })
      Cookies.set('SMARTCAF_USER_REGISTERED', true)
      state.profile = action.payload.profile
      state.isAuth = true
      state.loading = false
    })
    builder.addCase(socialLogin.rejected, (state, action) => {
      state.errorMsg = action.payload.response.data.message
      state.loading = false
    })

    // 13- DELETE PROFILE FILE
    builder.addCase(deleteProfileFile.pending, (state) => {
      state.deleteLoading = true
      state.errorDelete = null
    })
    builder.addCase(deleteProfileFile.fulfilled, (state, action) => {
      state.deleteLoading = false
      state.successMsg = action.payload.message
    })
    builder.addCase(deleteProfileFile.rejected, (state, action) => {
      state.errorDelete = action.payload.response.data.message
      state.deleteLoading = false
    })

    // 14- UPDATE EMAIL ADDRESS
    builder.addCase(updateEmail.pending, (state) => {
      state.updateLoading = true
      state.errorMsg = null
    })
    builder.addCase(updateEmail.fulfilled, (state, action) => {
      state.updateLoading = false
      state.successMsg = action.payload.message
    })
    builder.addCase(updateEmail.rejected, (state, action) => {
      state.errorMsg = action.payload.response.data.message
      state.errors = action.payload.response.data.errors
      state.updateLoading = false
    })

    // 15- VERIFY EMAIL ADDRESS
    builder.addCase(verifyEmail.pending, (state) => {
      state.updateLoading = true
      state.errorMsg = null
    })
    builder.addCase(verifyEmail.fulfilled, (state, action) => {
      state.updateLoading = false
      state.successMsg = action.payload.message
    })
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.errorMsg = action.payload.response.data.message
      state.updateLoading = false
    })

    // 16- GET PROFILE STATUS
    builder.addCase(getProfileStatus.pending, (state) => {
      state.profileStatusLoading = true
    })
    builder.addCase(getProfileStatus.fulfilled, (state, action) => {
      state.profileStatusLoading = false
      state.isCompleted = action.payload.is_completed
    })
    builder.addCase(getProfileStatus.rejected, (state) => {
      state.profileStatusLoading = false
    })

    // 17- GET PROFILE STATUS
    builder.addCase(deleteAccount.pending, (state) => {
      state.deleteAccountLoading = true
    })
    builder.addCase(deleteAccount.fulfilled, (state, action) => {
      state.deleteAccountLoading = false
    })
    builder.addCase(deleteAccount.rejected, (state) => {
      state.deleteAccountLoading = false
    })
  },
})

export const { logout, clearError } = authSlice.actions

export default authSlice.reducer
