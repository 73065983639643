import axios from '../lib/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 1- QUERY INFO POINTS
export const queryInfoPoints = createAsyncThunk(
  'thankyou/queryInfoPoints',
  async (code, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.get(`/public/thank_you_page_info/${code}`)

      return data.info_points
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 2- GET LATEST REQUEST ID
export const getLatestRequestId = createAsyncThunk(
  'thankyou/getLatestRequestId',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.get(`/service_request/latest_id/${id}`)
      return data.id
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const thankyouSlice = createSlice({
  name: 'thankyou',
  initialState: {
    InfoPoints: [],
    latestRequestID: null,
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    // 1- QUERY INFO POINTS
    builder.addCase(queryInfoPoints.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(queryInfoPoints.fulfilled, (state, action) => {
      state.loading = false
      state.InfoPoints = action.payload
    })
    builder.addCase(queryInfoPoints.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload.response.data.message
    })

    // 2- GET LATEST REQUEST ID
    builder.addCase(getLatestRequestId.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getLatestRequestId.fulfilled, (state, action) => {
      state.loading = false
      state.latestRequestID = action.payload
    })
    builder.addCase(getLatestRequestId.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload.response.data.message
    })
  },
})

export default thankyouSlice.reducer
