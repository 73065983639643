import axios from '../lib/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 1- GET COUNTRIES
export const queryCountries = createAsyncThunk(
  'countries/queryCountries',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI
    try {
      const { data } = await axios.get('/public/countries')
      return data.countries
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 

export const countriesSlice = createSlice({
  name: 'countries',
  initialState: {
    countries: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    // 1- GET COUNTRIES
    builder.addCase(queryCountries.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(queryCountries.fulfilled, (state, action) => {
      state.countries = action.payload
      state.loading = false
    })
    builder.addCase(queryCountries.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })
  },
})

export default countriesSlice.reducer