import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { HYDRATE } from 'next-redux-wrapper'

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.NEXT_PUBLIC_HOST}`,
  }),
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath]
    }
  },
  endpoints: (build) => ({
    getCategories: build.query({
      query: ({ locale, token }) => ({
        url: `public/categories?lang=${locale}`,
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }),
      transformResponse: (response) => response.categories,
    }),
    getServices: build.query({
      query: ({ keyword, category_id, locale, token }) => ({
        url: `public/services?target=user&lang=${locale}${
          keyword ? `&search=${keyword}` : ''
        }${category_id ? `&category_id=${category_id}` : ''}`,
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }),
      transformResponse: (response) => response.services,
    }),
    getUnfilteredServices: build.query({
      query: ({ locale }) => `public/services?target=user&lang=${locale}`,
      transformResponse: (response) => response.services,
    }),
    getServicesTitles: build.query({
      query: ({ locale }) => `public/services/titles?lang=${locale}`,
      transformResponse: (response) =>
        response.service_titles.map(({ title, slug }) => ({
          label: title,
          value: slug,
        })),
    }),
    getImportantServices: build.query({
      query: ({ locale, token }) => ({
        url: `public/services?target=user&lang=${locale}&is_important=1`,
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }),
      transformResponse: (response) => response.services,
    }),
    getServiceDetails: build.query({
      query: ({ id, locale, token }) => ({
        url: `/public/service/${id}?lang=${locale}`,
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }),
      transformResponse: (response) => response.service,
    }),

    // BLOG
    queryBlogs: build.query({
      query: ({ keyword, locale }) =>
        `/public/blogs?lang=${locale}${keyword ? `&search=${keyword}` : ''}`,
      transformResponse: (response) => response.blogs,
    }),
    queryBlogCategories: build.query({
      query: ({ locale }) => `/public/blog_categories?lang=${locale}`,
      transformResponse: (response) => response.blog_categories,
    }),
    queryNewBlogs: build.query({
      query: ({ locale }) => `/public/latest_blogs?lang=${locale}`,
      transformResponse: (response) => response.latest_blogs,
    }),
    getBlogDetails: build.query({
      query: ({ id, locale }) => `/public/blog/${id}?lang=${locale}`,
      transformResponse: (response) => response.blog,
    }),
  }),
})

// Export hooks for usage in functional components
export const {
  useGetUnfilteredServicesQuery,
  useGetServicesTitlesQuery,
  useQueryBlogCategoriesQuery,
  useQueryNewBlogsQuery,
  useGetServiceDetailsQuery,
  useGetImportantServicesQuery,
} = apiSlice

// export endpoints for use in SSR
export const {
  getCategories,
  getServices,
  getImportantServices,
  getServiceDetails,
  queryBlogs,
  queryNewBlogs,
  getBlogDetails,
} = apiSlice.endpoints
