import { axiosBaseQuery } from '../../lib/axios'
import { createApi } from '@reduxjs/toolkit/query/react'

export const dealsApi = createApi({
  reducerPath: 'dealsApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getBundles: builder.query({
      query: () => ({
        url: 'user/bundles',
        method: 'GET',
      }),
      transformResponse: (response) => response.bundles,
    }),

    getDefaultDeals: builder.query({
      query: (slug) => ({
        url: `user/bundles/${slug}/default/deals`,
        method: 'GET',
      }),
    }),

    getMyDeals: builder.query({
      query: (slug) => ({
        url: `user/bundles/${slug}/deals`,
        method: 'GET',
      }),
    }),

    createDeal: builder.mutation({
      query: ({ slug, formValues }) => ({
        url: `user/bundles/${slug}/deals`,
        method: 'POST',
        data: formValues,
      }),
    }),

    createPaymentIntent: builder.query({
      query: (deal_id) => ({
        url: `user/deals/${deal_id}/payment_intent`,
        method: 'GET',
      }),
    }),
  }),
})

export const {
  useGetBundlesQuery,
  useGetDefaultDealsQuery,
  useGetMyDealsQuery,
  useCreateDealMutation,
  useCreatePaymentIntentQuery,
} = dealsApi

export const { getBundles } = dealsApi.endpoints