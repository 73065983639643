import { createSlice } from '@reduxjs/toolkit'

export const progressSlice = createSlice({
  name: 'progress',
  initialState: {
    uploadProgress: 0,
  },
  reducers: {
    setUploadProgress: (state, action) => {
      state.uploadProgress = action.payload
    },
  },
})

export const { setUploadProgress } = progressSlice.actions;
export default progressSlice.reducer;