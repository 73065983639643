import axios from '../lib/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { setUploadProgress } from './progressSlice'

// 1- GET ALL SERVICES
export const getAllServices = createAsyncThunk(
  'service/getAllServices',
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.get('/public/services')
      return data.services
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 3- GET NEW FORM
export const getNewForm = createAsyncThunk(
  'service/getNewForm',
  async ({ id, isRenew, locale }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    const config = {
      headers: {
        lang: locale,
      },
    }

    try {
      const { data } = await axios.get(
        `v1/form/${id}${isRenew ? '?action=renew' : ''}`,
        config
      )
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 4- SUBMIT FORM
export const submitForm = createAsyncThunk(
  'service/submitForm',
  async (formData, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI

    dispatch(setUploadProgress(0))

    try {
      const onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        dispatch(setUploadProgress(percentCompleted))
      }

      const { data } = await axios.post('v1/submit/form', formData, {
        onUploadProgress,
      })
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 5- DRAFT FORM
export const draftForm = createAsyncThunk(
  'service/draftForm',
  async (formData, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI

    dispatch(setUploadProgress(0))

    try {
      const onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        dispatch(setUploadProgress(percentCompleted))
      }

      const { data } = await axios.post('v1/draft/form', formData, {
        onUploadProgress,
      })
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 6- RESET FORM
export const resetForm = createAsyncThunk(
  'service/resetForm',
  async (form_id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.post('v1/reset/form', { form_id })
      return data.message
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 7- DELETE DRAFT FILE
export const deleteDraftFile = createAsyncThunk(
  'service/deleteDraftFile',
  async (id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.delete(`v1/draft/file/${id}`)
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 8- SHOW FORM FOR EDIT
export const showFormForEdit = createAsyncThunk(
  'service/showFormForEdit',
  async (service_request_id, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.get(
        `v1/update/sr/form/${service_request_id}`
      )
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 9- EDIT FORM AFTER SUBMIT
export const editForm = createAsyncThunk(
  'service/editForm',
  async (formData, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI

    dispatch(setUploadProgress(0))

    try {
      const onUploadProgress = (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        dispatch(setUploadProgress(percentCompleted))
      }

      const { data } = await axios.post('v1/update/sr/form', formData, {
        onUploadProgress,
      })
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const serviceSlice = createSlice({
  name: 'service',
  initialState: {
    allServices: [],
    sumbittedRequest: null,
    form: null,
    newForm: null,
    service: {},
    intent: null,
    isProfileCompleted: false,
    loading: false,
    submitLoading: false,
    draftLoading: false,
    resetLoading: false,
    editLoading: false,
    error: null,
  },
  extraReducers: (builder) => {
    // 1- GET ALL SERVICES
    builder.addCase(getAllServices.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getAllServices.fulfilled, (state, action) => {
      state.allServices = action.payload
      state.loading = false
    })
    builder.addCase(getAllServices.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })

    // 3- GET NEW FORM
    builder.addCase(getNewForm.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(getNewForm.fulfilled, (state, action) => {
      state.newForm = action.payload.form
      state.service = action.payload.service
      state.intent = action.payload.intent
      state.isProfileCompleted = action.payload.isProfileCompleted
      state.loading = false
    })
    builder.addCase(getNewForm.rejected, (state, action) => {
      state.newForm = null
      state.service = null
      state.intent = null
      state.error = action.payload
      state.loading = false
    })

    // 4- SUBMIT FORM
    builder.addCase(submitForm.pending, (state) => {
      state.submitLoading = true
      state.error = null
    })
    builder.addCase(submitForm.fulfilled, (state, action) => {
      state.sumbittedRequest = action.payload
      state.submitLoading = false
    })
    builder.addCase(submitForm.rejected, (state, action) => {
      state.error = action.payload
      state.submitLoading = false
    })

    // 5- DRAFT FORM
    builder.addCase(draftForm.pending, (state) => {
      state.draftLoading = true
      state.error = null
    })
    builder.addCase(draftForm.fulfilled, (state) => {
      state.draftLoading = false
    })
    builder.addCase(draftForm.rejected, (state, action) => {
      state.error = action.payload
      state.draftLoading = false
    })

    // 6- RESET FORM
    builder.addCase(resetForm.pending, (state) => {
      state.resetLoading = true
      state.error = null
    })
    builder.addCase(resetForm.fulfilled, (state) => {
      state.resetLoading = false
    })
    builder.addCase(resetForm.rejected, (state, action) => {
      state.error = action.payload
      state.resetLoading = false
    })

    // 7- DELETE DRAFT FILE
    builder.addCase(deleteDraftFile.pending, (state) => {
      state.error = null
    })
    builder.addCase(deleteDraftFile.rejected, (state, action) => {
      state.error = action.payload
    })

    // 8- SHOW FORM FOR EDIT
    builder.addCase(showFormForEdit.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(showFormForEdit.fulfilled, (state, action) => {
      state.newForm = action.payload.form
      state.service = action.payload.service
      state.isProfileCompleted = action.payload.isProfileCompleted
      state.loading = false
    })
    builder.addCase(showFormForEdit.rejected, (state, action) => {
      state.newForm = null
      state.service = null
      state.error = action.payload
      state.loading = false
    })

    // 9- EDIT FORM AFTER SUBMIT
    builder.addCase(editForm.pending, (state) => {
      state.editLoading = true
      state.error = null
    })
    builder.addCase(editForm.fulfilled, (state) => {
      state.editLoading = false
    })
    builder.addCase(editForm.rejected, (state, action) => {
      state.error = action.payload
      state.editLoading = false
    })
  },
})

export default serviceSlice.reducer
