import { configureStore } from '@reduxjs/toolkit'
import { createWrapper } from 'next-redux-wrapper'

import { apiSlice } from './apiSlice'
import { luceEGasApi } from './api/luceEGasApi'
import { dealsApi } from './api/dealsApi'

import auth from './authSlice'
import service from './serviceSlice'
import request from './requestSlice'
import payment from './paymentSlice'
import contact from './contactSlice'
import notification from './notificationSlice'
import theme from './themeSlice'
import progress from './progressSlice'
import thankyou from './thankyouSlice'
import countries from './countriesSlice'
import signature from './signatureSlice'
import tempUpload from './tempUploadSlice'
import promoCode from './promoCodeSlice'

export const makeStore = () =>
  configureStore({
    reducer: {
      [apiSlice.reducerPath]: apiSlice.reducer,
      [luceEGasApi.reducerPath]: luceEGasApi.reducer,
      [dealsApi.reducerPath]: dealsApi.reducer,
      auth,
      service,
      request,
      payment,
      contact,
      notification,
      theme,
      progress,
      thankyou,
      countries,
      signature,
      tempUpload,
      promoCode,
    },
    middleware: (gDM) =>
      gDM().concat(
        apiSlice.middleware,
        luceEGasApi.middleware,
        dealsApi.middleware
      ),
  })

export const wrapper = createWrapper(makeStore)
