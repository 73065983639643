import axios from '../lib/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { profileData } from './authSlice'

// UPDATE LANGUAGE
export const updateLanguage = createAsyncThunk(
  'theme/updateLanguage',
  async (lang, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI

    try {
      const { data } = await axios.post('/language/edit', {
        _method: 'PUT',
        lang,
      })

      dispatch(profileData())
      return data.message
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    loading: false,
    error: null,
    message: null,
    navbarHeight: 0,
    dashboardNavbarHeight: 0,
    breadcrumbHeight: 0,
    dashboardTitle: '',
  },
  reducers: {
    setNavbarHeight: (state, action) => {
      state.navbarHeight = action.payload
    },
    setDashboardNavbarHeight: (state, action) => {
      state.dashboardNavbarHeight = action.payload
    },
    setBreadcrumbHeight: (state, action) => {
      state.breadcrumbHeight = action.payload
    },
    setDashboardTitle: (state, action) => {
      state.dashboardTitle = action.payload
    },
  },
  extraReducers: (builder) => {
    // UPDATE LANGUAGE
    builder.addCase(updateLanguage.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(updateLanguage.fulfilled, (state, action) => {
      state.message = action.payload
      state.loading = false
    })
    builder.addCase(updateLanguage.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })
  },
})

export const {
  setNavbarHeight,
  setDashboardNavbarHeight,
  setBreadcrumbHeight,
  setDashboardTitle,
} = themeSlice.actions
export default themeSlice.reducer
