import { axiosBaseQuery } from '../../lib/axios'
import { createApi } from '@reduxjs/toolkit/query/react'

export const luceEGasApi = createApi({
  reducerPath: 'luceEGasApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['luceEGas', 'offer', 'offerApplication'],
  endpoints: (builder) => ({
    getRequests: builder.query({
      query: ({ page, from_date, to_date, status, type }) => {
        const queryParams = new URLSearchParams()
        if (page) queryParams.append('page', page)
        if (from_date) queryParams.append('from_date', from_date)
        if (to_date) queryParams.append('to_date', to_date)
        if (status) queryParams.append('status', status)
        if (type) queryParams.append('type', type)

        return {
          url: `/v1/gl/requests?${queryParams.toString()}`,
          method: 'get',
        }
      },
      transformResponse: (response) => response.requests,
      providesTags: ['luceEGas'],
    }),

    getRequestDetails: builder.query({
      query: (request_id) => ({
        url: `/v1/gl/requests/${request_id}`,
        method: 'get',
      }),
      transformResponse: (response) => response.request,
      providesTags: (result, error, request_id) => [
        { type: 'luceEGas', id: request_id },
      ],
    }),

    createRequest: builder.mutation({
      query: (formValues) => ({
        url: '/v1/gl/requests',
        method: 'POST',
        data: formValues,
      }),
      invalidatesTags: (result, error, { request_id }) => [
        { type: 'luceEGas', id: request_id },
      ],
    }),

    getOfferDetails: builder.query({
      query: (offer_id) => ({
        url: `/v1/gl/offers/${offer_id}`,
        method: 'get',
      }),
      transformResponse: (response) => response.offer,
      providesTags: (result, error, offer_id) => [
        { type: 'offer', id: offer_id },
      ],
    }),

    getInitApplication: builder.query({
      query: (offer_id) => ({
        url: `/v1/gl/offer/${offer_id}/application`,
        method: 'get',
      }),
      transformResponse: (response) => response.application,
      providesTags: (result, error, offer_id) => [
        { type: 'offerApplication', id: offer_id },
      ],
    }),

    updateSubmitApplication: builder.mutation({
      query: ({ application_id, formData }) => ({
        url: `/v1/gl/application/${application_id}`,
        method: 'POST',
        data: formData,
      }),
      invalidatesTags: ['luceEGas'],
    }),
  }),
})

export const {
  useGetRequestsQuery,
  useGetRequestDetailsQuery,
  useCreateRequestMutation,
  useGetOfferDetailsQuery,
  useGetInitApplicationQuery,
  useUpdateSubmitApplicationMutation,
} = luceEGasApi
