import axios from '../lib/axios'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// 1- QUERY NOTIFICATIONS
export const queryNotifications = createAsyncThunk(
  'notification/queryNotifications',
  async (page, thunkAPI) => {
    const { rejectWithValue } = thunkAPI

    try {
      const { data } = await axios.get(`/notifications?page=${page}`)
      return {
        notifications: data.notifications.data,
        pagination: {
          current: data.notifications.meta.current_page,
          from: data.notifications.meta.from,
          to: data.notifications.meta.to,
          total: data.notifications.meta.total,
          last: data.notifications.meta.last_page,
        },
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

// 2- Mark AS SEEN
export const markAsSeen = createAsyncThunk(
  'notification/markAsSeen',
  async (_, thunkAPI) => {
    const { rejectWithValue, dispatch } = thunkAPI

    try {
      const { data } = await axios.get('/notifications/mark_as_seen')
      dispatch(queryNotifications())
      return data
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notifications: [],
    pagination: null,
    loading: false,
    error: null,
  },
  reducers: {
    AddNotification: (state) => {
      state.notifications.push(state)
    },
  },
  extraReducers: (builder) => {
    // 1- QUERY NOTIFICATIONS
    builder.addCase(queryNotifications.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(queryNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload.notifications
      state.pagination = action.payload.pagination
      state.loading = false
    })
    builder.addCase(queryNotifications.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })

    // 2- Mark AS SEEN
    builder.addCase(markAsSeen.pending, (state) => {
      state.loading = true
      state.error = null
    })
    builder.addCase(markAsSeen.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(markAsSeen.rejected, (state, action) => {
      state.error = action.payload
      state.loading = false
    })
  },
})

export const { AddNotification } = notificationSlice.actions

export default notificationSlice.reducer
